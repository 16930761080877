<template>
  <defs>
    <!-- HOUSE1 DEFINITION  -->
    <g id="originalHouse1">
      <path 
        d="M68.66,23.12c-22.67,4-42.29,8.11-45.42,10.51C16,39.24,9.51,46.53,11.75,55.5s30.56,47.38,30.56,47.38H61.43V45.61a13.32,13.32,0,0,1,7.23-11.1"
        style="fill: none;stroke: #000;stroke-miterlimit: 10"
      />
      <path 
        d="M89,33.11c10.68,3.26,10.62,12.5,10.62,12.5v57.27h17s47.1-52.15,47.1-69.25-3.92-20.75-19.9-20.75c-7.14,0-30.5,3-54.82,6.87"
        style="fill: none;stroke: #000;stroke-miterlimit: 10"
      />
      <path 
        d="M68.66,23.12C75.29,22,82.17,20.82,89,19.75" 
        style="fill: none;stroke: #000;stroke-miterlimit: 10"
      />
      <path 
        d="M89,33.11A28.62,28.62,0,0,0,80.52,32c-5.28,0-9.1,1-11.86,2.54" 
        style="fill: none;stroke: #000;stroke-miterlimit: 10"
      />
    </g>
    <!-- HOUSE2 DEFINITION  -->
    <path 
      id="originalHouse2" 
      stroke="#000" 
      stroke-width="1.5" 
      fill="#fff" 
      d="m186.289214,66.49954l0,-21.72209c-0.10047,-5.39651 -2.18891,-12.04253
            -7.79395,-18.4766c-5.52251,-6.37079 -15.48383,-11.76732 -28.48809,-11.66293c-13.01503,-0.10123 -22.97635,5.29529 -28.49527,11.66293c-5.60504,6.43407
            -7.69705,13.08324 -7.80112,18.47977l0,21.71892l17.94187,0l0,-21.71892c-0.0969,-1.19256 1.08728,-5.65907 3.94362,-8.69265c2.93888,-3.09683 6.63131,-5.5262
            14.40372,-5.62426c7.76883,0.10123 11.46486,2.52743 14.40015,5.62426c2.86352,3.03358 4.04051,7.50326 3.94361,8.69265l0,21.71892l17.94546,0z"
    />
    <!-- BORDERS DEFINITION  -->
    <rect 
      id="originalBorder" 
      stroke="#000" 
      opacity="0.2" 
      height="301.99999" 
      width="251" 
      y="68" 
      x="24.5" 
      fill-opacity="null" 
      stroke-opacity="null" 
      stroke-width="1.5"
      fill="#fff"
    />
    <!-- RFID DEFINITION -->
    <g id="originalRFID">
      <rect 
        fill="#999999" 
        stroke-width="0" 
        stroke-opacity="null" 
        x="63.69231" 
        y="55.69231" 
        width="12.61538" 
        height="26.76923" 
        stroke="#000"
      />
      <rect 
        v-if="!spot.rRFIDOpen" 
        fill="#666666" 
        stroke="#000" 
        stroke-width="0" 
        stroke-opacity="null" 
        x="73.53846" 
        y="57.84615" 
        width="14.46154" 
        height="7.69231"
      />
    </g>
    <!-- PERSON DEFINITION -->
    <g id="originalPerson">
      <ellipse 
        ry="6.874965" 
        rx="6.874965" 
        cy="40.970974" 
        cx="91.743332" 
        fill-opacity="null" 
        stroke-opacity="null" 
        stroke-width="1.5" 
        stroke="#000" 
        fill="none"
      />
      <line 
        stroke="#000" 
        stroke-linecap="null" 
        stroke-linejoin="null" 
        y2="76.095795" 
        x2="78.368402" 
        y1="65.47085" 
        x1="100.243289" 
        fill-opacity="null"
        stroke-opacity="null" 
        stroke-width="1.5" 
        fill="none"
      />
      <line 
        stroke-linecap="null" 
        stroke-linejoin="null" 
        y2="69.845827" 
        x2="90.618338" 
        y1="47.970939" 
        x1="91.243335" 
        fill-opacity="null" 
        stroke-opacity="null"
        stroke-width="1.5" 
        stroke="#000" 
        fill="none"
      />
      <ellipse 
        stroke="#000" 
        ry="1.562492" 
        rx="1.687491" 
        cy="38.408487" 
        cx="87.305854" 
        fill-opacity="null" 
        stroke-opacity="null" 
        stroke-width="1.5" 
        fill="none"
      />
      <line 
        stroke-linecap="null" 
        stroke-linejoin="null" 
        y2="42.220968" 
        x2="89.118346" 
        y1="44.220958" 
        x1="86.36836" 
        fill-opacity="null" 
        stroke-opacity="null"
        stroke-width="1.5" 
        stroke="#000" 
        fill="none"
      />
      <line 
        stroke-linecap="null" 
        stroke-linejoin="null" 
        y2="56.096918" 
        x2="85.244128" 
        y1="53.59693" 
        x1="90.7441" 
        fill-opacity="null" 
        stroke-opacity="null"
        stroke-width="1.5" 
        stroke="#000" 
        fill="none"
      />
    </g>
    <!-- FLAG DEFINITION -->
    <g id="originalFlag">
      <rect 
        fill="#6d97ab" 
        stroke-width="0" 
        x="79.50011" 
        y="118.67778" 
        width="13.99997" 
        height="8.39998" 
        stroke="#b7b72d"
      />
      <line 
        fill="none" 
        x1="79.76106" 
        y1="127.0682" 
        x2="79.76106" 
        y2="139.89725" 
        stroke-linejoin="null" 
        stroke-linecap="null" 
        stroke="#646a6d"
      />
      <line 
        fill="none" 
        x1="93.23439" 
        y1="127.07456" 
        x2="93.23439" 
        y2="139.90361" 
        stroke-linejoin="null" 
        stroke-linecap="null" 
        stroke="#646a6d"
      />
    </g>
    <!-- SLOPES DEFINITION -->
    <g id="originalSlope">
      <path 
        fill="#fff" 
        stroke-width="1.5" 
        stroke-opacity="null" 
        fill-opacity="null" 
        opacity="0.5" 
        stroke-dasharray="2,2" 
        stroke="#000"
        d="m150.5,79.00002c0,0 -181,88.8387 -10,120.4258c171,31.58709 -12,185.57418 -12,185.57418"
      />
      <use 
        xlink:href="#originalFlag" 
        transform="scale(1, 1) translate(0, 20)"
      />
      <use 
        xlink:href="#originalFlag" 
        transform="scale(1, 1) translate(100, 120)"
      />
    </g>
    <!-- BAGJUMP AREA DEFINITION -->
    <rect 
      id="originalBag" 
      stroke="#000" 
      stroke-dasharray="2,2" 
      height="93" 
      width="142" 
      y="256" 
      x="77.5" 
      stroke-opacity="null" 
      stroke-width="1.5" 
      fill="#dee5e5"
    />
    <!-- GOAL DEFINITION -->
    <g id="originalGoal">
      <rect 
        fill="#fff" 
        stroke="#000" 
        stroke-width="1.5" 
        stroke-opacity="null" 
        fill-opacity="null" 
        x="105.26881" 
        y="310.68131" 
        width="89.46237" 
        height="20.21505"
      />
      <text 
        fill="#000000" 
        stroke="#000" 
        stroke-width="0" 
        stroke-opacity="null" 
        fill-opacity="null" 
        x="123.79216" 
        y="326.16518" 
        font-size="16"
        font-family="Helvetica, Arial, sans-serif" 
        text-anchor="start" 
        xml:space="preserve"
      >
        FINISH
      </text>
      <line 
        fill="none" 
        stroke="#000" 
        stroke-width="1.5" 
        stroke-opacity="null" 
        fill-opacity="null" 
        x1="105.26881" 
        y1="331.6188" 
        x2="105.26881" 
        y2="370.33087"
        stroke-linejoin="null" 
        stroke-linecap="null"
      />
      <line 
        fill="none" 
        stroke="#000" 
        stroke-width="1.5" 
        stroke-opacity="null" 
        fill-opacity="null" 
        x1="194.70564" 
        y1="331.6188" 
        x2="194.70564" 
        y2="370.33087"
        stroke-linejoin="null" 
        stroke-linecap="null"
      />
    </g>
    <!-- CAMERA DEFINITION -->
    <g id="originalCamera">
      <rect 
        height="17.4683" 
        width="34.93661" 
        y="121.36176" 
        x="256.93646" 
        fill-opacity="null" 
        stroke-opacity="null" 
        stroke-width="1.5" 
        stroke="#000" 
        fill="#fff"
      />
      <line 
        stroke-linecap="null" 
        stroke-linejoin="null" 
        y2="122.80239" 
        x2="249.60032" 
        y1="130.02208" 
        x1="256.82" 
        fill-opacity="null" 
        stroke-opacity="null"
        stroke-width="1.5" 
        stroke="#000" 
        fill="none"
      />
      <line 
        stroke-linecap="null" 
        stroke-linejoin="null" 
        y2="136.29454" 
        x2="249.73508" 
        y1="129.33461" 
        x1="256.69501" 
        fill-opacity="null" 
        stroke-opacity="null"
        stroke-width="1.5" 
        stroke="#000" 
        fill="none"
      />
      <line 
        stroke="#000" 
        stroke-linecap="null" 
        stroke-linejoin="null" 
        y2="136.77233" 
        x2="249.50785" 
        y1="122.33495" 
        x1="249.50785" 
        fill-opacity="null"
        stroke-opacity="null" 
        stroke-width="1.5" 
        fill="none"
      />
    </g>
  </defs>
</template>

<script>
export default {
  name: "SpotDefinitions",
  props: {
    spot: {
      type: Object,
      required: true
    }
  }
}
</script>
